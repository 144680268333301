import React from "react";
import { AppBtn } from "../../ui/buttons";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";

interface Props {
  storeName: string;
  show: boolean;
  toggle: (state: boolean) => void;
}

const ZillaModal: React.FC<Props> = ({ storeName, show, toggle }) => {
  const steps = [
    <>
      Create an account on{" "}
      <a href="usezilla.com" className="text-primary-500 font-medium">
        Zilla
      </a>
      , verify your identity and get up to ₦300k in shopping credits
    </>,
    <>
      Select the items you want to buy, add your delivery details and click on{" "}
      <b className="font-medium">Place Order</b>{" "}
    </>,
    <>
      Click on <b className="font-medium">Pay Now</b>, then select pay with Zilla
    </>,
  ];

  return (
    <Modal {...{ show, toggle }} title="Buy now, pay later with Zilla" bgClose={false} size="midi">
      <ModalBody>
        <div className="flex flex-col items-center mt-3">
          <figure className="h-17.5 w-17.5 rounded-full flex items-center justify-center overflow-hidden">
            <img src="/images/zilla-purple.jpeg" className="h-full w-full" alt="Zilla logo purple" />
          </figure>
          <h2 className="text-black text-2lg sm:text-2xl font-semibold mt-4 leading-tight text-center">
            Buy now, pay later on <br /> {storeName} with Zilla.
          </h2>
        </div>
        <div className="bg-grey-fields-100 p-4 sm:p-4.5 rounded-15 mt-4 sm:mt-5">
          <p className="text-black text-1sm font-medium">You can pay in 2 - 4 installments with Zilla.</p>
          <ul className="space-y-3 max-w-md mx-auto mt-3">
            {steps.map((step, index) => (
              <li className="flex items-start" key={index}>
                <figure className="h-4.5 w-4.5 bg-success flex items-center justify-center text-white rounded-full flex-shrink-0 mr-2 mt-0.5">
                  {/* prettier-ignore */}
                  <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="12" fill="#39B588"/>
                    <path d="M10.2951 16.5C10.055 16.5 9.82691 16.3995 9.65883 16.2238L6.26113 12.6702C5.91296 12.3061 5.91296 11.7033 6.26113 11.3392C6.6093 10.9751 7.18559 10.9751 7.53377 11.3392L10.2951 14.2272L16.4662 7.77311C16.8144 7.40896 17.3907 7.40896 17.7389 7.77311C18.087 8.13725 18.087 8.73997 17.7389 9.10412L10.9315 16.2238C10.7634 16.3995 10.5353 16.5 10.2951 16.5Z" fill="white"/>
                  </svg>
                </figure>
                <span className="inline-block text-dark text-sm max-w-sm">{step}</span>
              </li>
            ))}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <AppBtn onClick={() => toggle(false)} isBlock>
          Alright, got it
        </AppBtn>
      </ModalFooter>
    </Modal>
  );
};

export default ZillaModal;
