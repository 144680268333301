import React, { useEffect, useRef, useState } from "react";
import { SmoothHorizontalScrolling } from "../../assets/js/utils/functions";
import useSlider from "../hooks/useSlider";
import Modal, { ModalBody } from "../ui/modal";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  images: string[];
  current?: number;
  title?: string;
}

const ImageCarouselModal: React.FC<Props> = ({ show, toggle, images, current = 0, title }) => {
  const itemsCount = images.length;
  const { next, slider, slide, previous, slides, currentSlide, switchSlides, hasNext, hasPrevious } = useSlider({
    slides: itemsCount,
    sameWidthAsSlider: true,
    gap: 0,
  });

  useEffect(() => {
    switchSlides(current);
  }, [current]);

  return (
    <Modal title={title || "Product Images"} {...{ show, toggle }}>
      <ModalBody noPadding className="relative">
        <ul
          className="grid overflow-x-hidden"
          style={{ gridTemplateColumns: `repeat(${images.length}, 100%)`, scrollBehavior: "smooth" }}
          ref={slider}
        >
          {images.map((image, index) => (
            <div className="w-full p-5 sm:p-6.25" key={index}>
              <figure className="w-full relative" style={{ paddingTop: "100%" }} ref={slide}>
                <img
                  src={image}
                  alt=""
                  className="max-w-full max-h-full absolute top-1/2  left-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                />
              </figure>
            </div>
          ))}
        </ul>
        <div className="flex w-full justify-between px-5 sm:px-6.25 absolute top-1/2 -translate-y-1/2 transform">
          {hasPrevious && (
            <button
              className="h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 flex items-center justify-center shadow-md"
              onClick={previous}
            >
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path
                  d="M9.375 3.75L5.625 7.5L9.375 11.25"
                  stroke="#FCFBFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
          {hasNext && (
            <button
              className="h-10 w-10 rounded-full bg-primary-500 hover:bg-primary-700 flex items-center justify-center shadow-md ml-auto"
              onClick={next}
            >
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.625 11.25L9.375 7.5L5.625 3.75"
                  stroke="#FCFBFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageCarouselModal;
