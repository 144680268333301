import { useEffect, useRef, useState } from "react";

interface SliderData {
  slides: number;
  gap: number;
  sameWidthAsSlider?: boolean;
}

//set the destination scroll left position
function scrollFunction(e, sc, eAmt, start) {
  e.scrollLeft = eAmt * sc + start;
}

//incrementally slide the slider mimicing smoothscroll
function SmoothHorizontalScrolling(e: HTMLElement, time: number, amount: number, start: number) {
  const eAmt = amount / 100;
  let curTime = 0;
  let scrollCounter = 0;
  while (curTime <= time) {
    window.setTimeout(scrollFunction, curTime, e, scrollCounter, eAmt, start);
    curTime += time / 100;
    scrollCounter++;
  }
}

const useSlider = (data: SliderData) => {
  const { slides, gap, sameWidthAsSlider = true } = data;
  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = useRef<HTMLUListElement>(null);
  const slide = useRef<HTMLDivElement>(null);
  const hasNext = currentSlide < slides - 1;
  const hasPrevious = currentSlide > 0;

  useEffect(() => {
    handleSlideChange();

    window.addEventListener("resize", () => handleSlideChange(false));

    return () => window.removeEventListener("resize", () => handleSlideChange(false));
  }, [currentSlide, slider]);

  function handleSlideChange(animate = true) {
    const sliderEl = slider.current;
    const slideEl = slide.current;

    const slideWidth = sameWidthAsSlider || !slideEl ? sliderEl?.clientWidth : slideEl?.clientWidth;

    if (!sliderEl) {
      return;
    }

    if (animate) {
      const slidePosition = currentSlide * slideWidth;
      const slideAmount = slidePosition - sliderEl?.scrollLeft + currentSlide * gap;

      SmoothHorizontalScrolling(sliderEl, 500, slideAmount, sliderEl.scrollLeft);
    } else {
      sliderEl.scrollLeft = currentSlide * slideWidth + currentSlide * gap;
    }
  }

  function next() {
    const nextSlide = currentSlide === slides - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(nextSlide);
  }

  function previous() {
    const nextSlide = currentSlide === 0 ? slides - 1 : currentSlide - 1;
    setCurrentSlide(nextSlide);
  }

  function switchSlides(slide: number) {
    setCurrentSlide(slide);
  }

  return {
    slides,
    slider,
    currentSlide,
    next,
    previous,
    switchSlides,
    slide,
    hasNext,
    hasPrevious,
  };
};

export default useSlider;
