import React from "react";
import { getAvatarBg } from "../../assets/js/utils/functions";
import LazyImage from "../lazy-image";

interface Props {
  image: string;
  text: string;
  className?: string;
}

const ImageWithPlaceholder: React.FC<Props> = ({ text, image, className = "" }) => {
  return (
    <figure
      className={`${className} rounded-full border border-grey-divider flex-shrink-0 flex items-center justify-center text-white font-bold relative overflow-hidden uppercase ${
        image ? "bg-grey-loader" : getAvatarBg(text)
      }`}
    >
      {image ? (
        <LazyImage src={image} alt="" className="h-full w-full object-cover" loaderClasses="rounded-full" />
      ) : (
        text?.charAt(0)
      )}
    </figure>
  );
};

export default ImageWithPlaceholder;
